<template>
  <div>
    <h2 v-if="getAdminUsers.length" class="font-medium text-base mb-16 px-16 sm:px-0">Admins</h2>
    <el-table :headings="tableHeadings" :items="getAdminUsers" class="mb-32">
      <template #enable_order_email="{ item }">
        <el-checkbox v-model="item.enable_order_email" readonly />
      </template>

      <template #edit-button="{ item }">
        <el-button @click="onEdit(item.id, item.role)">Edit</el-button>
      </template>
    </el-table>

    <h2 v-if="getAdvertiserUsers.length" class="font-medium text-base mb-16 px-16 sm:px-0">Advertisers</h2>
    <el-table :headings="tableHeadings" :items="getAdvertiserUsers">
      <template #enable_order_email="{ item }">
        <el-checkbox v-model="item.enable_order_email" readonly />
      </template>

      <template #edit-button="{ item }">
        <el-button @click="onEdit(item.id, item.role)">Edit</el-button>
      </template>
    </el-table>
  </div>
</template>

<script>
const ElTable = () => import('@/components/shared/ELTable')
const ElButton = () => import('@/components/shared/ELButton')
const ElCheckbox = () => import('@/components/shared/ELCheckbox')

export default {
  name: 'UsersTable',

  components: { ElTable, ElButton, ElCheckbox },

  props: {
    items: { type: Array, default: () => [] }
  },

  data () {
    return {
      tableHeadings: [
        { label: 'Name', value: 'full_name' },
        { label: 'Email', value: 'email' },
        { label: 'Order email', value: 'enable_order_email' },
        // { label: 'Rolle', value: 'role' },
        { label: 'Handlinger', value: 'edit-button' }
      ]
    }
  },

  computed: {
    getAdminUsers () {
      return this.items.filter(user => user.role === 'admin')
    },

    getAdvertiserUsers () {
      return this.items.filter(user => user.role === 'advertiser')
    }
  },

  methods: {
    onEdit (userId, role) {
      if (role === 'advertiser') this.$router.push({ name: 'AdvertiserUserEdit', params: { id: userId } })
      if (role === 'admin') this.$router.push({ name: 'AdminUserEdit', params: { id: userId } })
    }
  }
}
</script>
